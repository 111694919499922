import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Main } from "./features";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";

const App = () => {

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </Router>
  );
};

export default App;
