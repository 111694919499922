import { useLocation } from "react-router-dom";
import { splitByCommonChars, trimLeadingSlashes } from "./stringUtils";

const prepositionWords = ["TO", "IN", "AS"];

/**
 * Attempts to parse the semi-natural-language url location as { from, to, amount }
 * If doesn't fit the expected pattern returns a default.
 */
export const useUrlParse = () => {
  const { pathname } = useLocation();

  const asUppercase = pathname.toUpperCase();

  const withLeadingSlashesTrimmed = trimLeadingSlashes(asUppercase);

  const splitStrings = splitByCommonChars(withLeadingSlashesTrimmed);

  const resultAsArray = splitStrings.filter(
    item => !prepositionWords.includes(item)
  );

  if (resultAsArray.length === 2) {
    return {
      amount: 1,
      from: resultAsArray[0],
      to: resultAsArray[1]
    };
  }

  if (resultAsArray.length === 3) {
    return {
      amount: resultAsArray[0],
      from: resultAsArray[1],
      to: resultAsArray[2]
    };
  }

  return {
    from: "BTC",
    to: "ETH",
    amount: 1
  };
};
