import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  typography: {
    textAlign: "center"
  },
  link: {
    color: theme.palette.primary.contrastText
  }
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography color="textPrimary" classes={{root: classes.typography}}>
        Made with 💙 for the community by{" "}
        <a className={classes.link} href="https://twitter.com/mdurkin92">
          @mdurkin92
        </a>
        . OK;LG.
      </Typography>
    </Box>
  );
};
