import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    button: {
        minHeight: "36px",
        border: `4px solid ${theme.palette.common.white}`
    },
    icon: {
        marginLeft: theme.spacing(1)
    }
}));

export const CopyLinkBtn = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <Button variant="contained" color="primary" className={classes.button} {...rest}>
      Copy link
      <LinkIcon className={classes.icon} />
    </Button>
  );
};
