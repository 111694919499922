import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { supportedAssets } from "chainlink-asset-converter";

export const CurrencySelect = ({
  value = supportedAssets[0],
  ...rest
}) => {

  const supportedAssetsAlphabeticallySorted = [...supportedAssets].sort((a, b) => {
    const toUpperA = a.code.toUpperCase();
    const toUpperB = b.code.toUpperCase();
    return (toUpperA < toUpperB) ? -1 : (toUpperA > toUpperB) ? 1 : 0;
  })

  return (
    <Autocomplete
      options={supportedAssetsAlphabeticallySorted}
      disableClearable={true}
      getOptionLabel={(option: any) => option.code}
      renderInput={(params: any) => (
        <TextField {...params} variant="outlined" />
      )}
      value={value}
      {...rest}
    />
  );
};
