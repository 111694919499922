import React, { useState } from "react";
import { ReactComponent as CopyIcon } from "./copyIcon.svg";
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundImage: `linear-gradient(45deg, rgba(251,126,36,1) 0%, rgba(251,176,36,1) 56%, rgba(251,209,36,1) 100%)`,
    backgroundSize: "300%",
    borderRadius: "4px",
    animation: "$container-anim 4s infinite alternate"
  },
  root: {
    color: "black"
  },
  "@keyframes container-anim": {
    "0%": { backgroundPosition: "left" },
    "100%": { backgroundPosition: "right" }
  },
  iconButtonRoot: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  spinner: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  copyIcon: ({isLoading}: any) => ({
    fill: isLoading ? theme.palette.grey[600] : theme.palette.common.black
  })
}));

type ResultProps = {
  isLoading: boolean;
  result: string;
};

export const Result = ({ isLoading = false, result }: ResultProps) => {
  const classes = useStyles({ isLoading });

  const [isClosed, setIsClosed] = useState<boolean>(true);
  const [tooltip, setTooltip] = useState<string>("Copy to clipboard");

  const handleCopyToClipboard = (event: any) => {
    navigator.clipboard.writeText(result);
    setTooltip("Copied!");
  };

  const handleTooltipOpen = () => {
    if (!!isClosed && !isLoading) {
      setTooltip("Copy to clipboard");
      setIsClosed(false);
    }
  };

  const handleTooltipClose = () => {
    !isClosed && setIsClosed(true);
  };

  const formatResult = (result: string) => {

    if (result === "") return result
    
    const asFloat = parseFloat(result)

    if (isNaN(asFloat)) return '';

    if (asFloat >= 100000000) return asFloat.toExponential(3)

    if (asFloat >= 1.) return asFloat.toFixed(2);

    if (asFloat < 1.) return asFloat.toPrecision(4);

    return '';
  }

  return (
    <Box className={classes.container}>
      {isLoading ? (
        <CircularProgress size={25} className={classes.spinner} />
      ) : (
        <Typography
          classes={{
            root: classes.root
          }}
        >
          {formatResult(result)}
        </Typography>
      )}
      <Tooltip
        title={tooltip}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
      >
        <IconButton
          disabled={isLoading}
          aria-label="copy to clipboard"
          classes={{ root: classes.iconButtonRoot }}
          onClick={handleCopyToClipboard}
        >
          <CopyIcon className={classes.copyIcon} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
