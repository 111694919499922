import React from "react";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import { GitHub } from "@material-ui/icons";
import { ReactComponent as Cubes } from "./cubes.svg";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: theme.spacing(4),
    boxSizing: "border-box",
    position: "relative"
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  githubBtn: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(2),
      right: theme.spacing(2)
    }
  }
}));

export const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.inner}>
        <Cubes width="240px" />
        <a
          href="https://github.com/PickleyD/chainlink-asset-converter"
          className={classes.githubBtn}
        >
          <IconButton>
            <GitHub />
          </IconButton>
        </a>
      </Box>
    </Box>
  );
};
