import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useDebouncedCallback } from "use-debounce";
import { CurrencySelect } from "../../components/CurrencySelect";
import { convert } from "chainlink-asset-converter";
import { Result } from "./Result";
import { supportedAssets } from "chainlink-asset-converter";
import { SwapBtn } from "./SwapBtn";
import { CopyLinkBtn } from "./CopyLinkBtn";
import { useUrlParse } from "../index";

const useStyles = makeStyles(theme => ({
  swapContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    "&> *": {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  input: {
    "& .MuiOutlinedInput-input": {
      borderWidth: "4px"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "4px"
    },
    minWidth: "140px",
    maxWidth: "140px"
  },
  group1: {
    display: "flex",
    flexDirection: "row",
    "&> *": {
      margin: theme.spacing(1)
    }
  },
  group2: {
    display: "flex",
    flexDirection: "row",
    "&> *": {
      margin: theme.spacing(1)
    }
  },
  actionBtnContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "&> *": {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  }
}));

export const Converter = ({ className, ...rest }: any) => {
  const classes = useStyles();

  const { from: urlFromCode, to: urlToCode, amount: urlAmount } = useUrlParse();

  const getSupportedAssetByCode = (code: string) =>
    supportedAssets.find((asset: any) => asset.code === code);

  const defaultFrom =
    getSupportedAssetByCode(urlFromCode) ?? getSupportedAssetByCode("BTC");
  const defaultTo =
    getSupportedAssetByCode(urlToCode) ?? getSupportedAssetByCode("ETH");
  const defaultAmount = urlAmount ?? 1;

  const [amount, setAmount] = useState<number | string>(defaultAmount);
  const [from, setFrom] = useState(defaultFrom);
  const [to, setTo] = useState(defaultTo);
  const [result, setResult] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleAmountChange = (evt: any) => {
    setAmount(evt.target.value);
  };

  const handleAmountBlur = (evt: any) => {
    if (evt.target.value === "") setAmount(0);
  };

  const handleFromChange = (evt: any, newValue: any) => {
    setFrom(newValue);
  };

  const handleToChange = (evt: any, newValue: any) => {
    setTo(newValue);
  };

  const updateResultAsync = useDebouncedCallback(
    async () => {
      try {
        if (amount === "") return;

        setIsLoading(true);

        // Update the url but don't worry if it fails
        try {
          window.history.replaceState(null, "", getFullUrl());
        } catch (error) {
          console.error(error)
        }

        const newResult = await convert({
          amount: parseFloat(amount.toString()),
          from: from ? from.code : "BTC",
          to: to ? to.code : "ETH",
          endpoint: process.env.REACT_APP_RPC_ENDPOINT
        });

        setResult(newResult);
      } catch (ex) {
        console.error(ex);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    500,
    { maxWait: 2000 }
  );

  useEffect(() => {
    updateResultAsync.callback();
  }, [amount, from, to, updateResultAsync]);

  const handleSwap = () => {
    const oldFrom = from;
    const oldTo = to;

    setFrom(oldTo);
    setTo(oldFrom);

    const resultAsFloat = parseFloat(result);
    if (!isNaN(resultAsFloat)) {
      setAmount(resultAsFloat);
    }
  };

  const getFullUrl = () => {
    const parts = [
      amount.toString() === "1" ? "" : amount.toString(),
      from?.code.toLowerCase(),
      "to",
      to?.code.toLowerCase()
    ];

    const joinedParts = parts.filter(part => part && part.length > 0).join("-");

    return `${window.location.origin}/${joinedParts}`;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(getFullUrl());
  };

  return (
    <Box className={`${classes.swapContainer} ${className}`} {...rest}>
      <Box className={classes.container}>
        <Box className={classes.group1}>
          <TextField
            id="amount-textfield"
            value={amount}
            onChange={handleAmountChange}
            variant="outlined"
            type="number"
            classes={{ root: classes.input }}
            onBlur={handleAmountBlur}
          />
          <FormControl>
            <CurrencySelect
              value={from}
              onChange={handleFromChange}
              id="from-select"
              classes={{ root: classes.input }}
            />
          </FormControl>
        </Box>
        <Typography component="span" color="textPrimary">
          =
        </Typography>
        <Box className={classes.group2}>
          <Result result={result} isLoading={isLoading} />
          <FormControl>
            <CurrencySelect
              value={to}
              onChange={handleToChange}
              id="to-select"
              classes={{ root: classes.input }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.actionBtnContainer}>
        <SwapBtn onClick={handleSwap} disabled={isLoading} />
        <CopyLinkBtn onClick={handleCopyLink} />
      </Box>
    </Box>
  );
};
