import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Cached as SwapIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    button: ({disabled}: any) => ({
        minHeight: "36px",
        border: `4px solid ${disabled ? theme.palette.grey[600] : theme.palette.common.white}`
    }),
    icon: {
        marginLeft: theme.spacing(1)
    }
}));

export const SwapBtn = ({ disabled, ...rest }: any) => {
  const classes = useStyles({ disabled });

  return (
    <Button variant="contained" color="primary" className={classes.button} disabled={disabled} {...rest}>
      Swap
      <SwapIcon className={classes.icon} />
    </Button>
  );
};
