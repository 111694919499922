import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "800px",
    margin: `${theme.spacing(12)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px ${theme.spacing(2)}px`,
  },
  link: {
    color: theme.palette.common.white,
  },
}));

export const Faq = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Why?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            I made this as a hobby project and I hope you enjoy playing with it.
            This site also serves as a demo of the open source library{" "}
            <a
              className={classes.link}
              href="https://github.com/PickleyD/chainlink-asset-converter"
            >
              <code>chainlink-asset-converter</code>
            </a>
            .
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>How does it work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The{" "}
            <a className={classes.link} href="https://chain.link/">
              Chainlink
            </a>{" "}
            network of decentralized oracles provide a large number of price
            feeds between both fiat and crypto pairs. By modelling these pairs
            as branches of a tree and using an algorithm to find the shortest
            path from one asset to another we can find the exchange rate between
            any supported asset.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>What is chainlink-asset-converter?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The{" "}
            <a
              className={classes.link}
              href="https://github.com/PickleyD/chainlink-asset-converter"
            >
              <code>chainlink-asset-converter</code>
            </a>{" "}
            library aims to provide utility functions to make working with
            Chainlink price feeds even easier for developers working with
            javascript.
            <br />
            <br />
            Chainlink price feeds are currently securing $billions in
            decentralized finance and are currently sponsored by an array of
            industry-leading projects. You don't <b>have</b> to build a
            decentralized application to benefit from this highly reliable data
            - you can make use of this library in any web app!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
