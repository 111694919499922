import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "800px",
    padding: theme.spacing(8),
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  link: {
      color: theme.palette.primary.contrastText
  }
}));

export const Intro = () => {
  const classes = useStyles();

  return (
    <Typography
      variant="h5"
      component="h2"
      color="textPrimary"
      classes={{ root: classes.root }}
      className="animate__animated animate__fadeInUp"
    >
      Quick & easy exchange rate calculator between any assets supported by&nbsp;
      <a className={classes.link} href="https://data.chain.link">Chainlink price feeds</a>
    </Typography>
  );
};
