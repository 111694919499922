import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import "animate.css";
import { Header, Intro, Converter, Faq, Footer } from "../index";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  main: {
    flex: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  }
}));

export const Main = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Header />
      <Box className={classes.main}>
        <Intro />
        <Converter className="animate__animated animate__fadeInUp" />
        <Faq />
      </Box>
      <Footer />
    </Box>
  );
};
