import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif"
  },
  palette: {
    type: "dark",
    primary: {
      main: "#2a1a5e"
    },
    secondary: {
      main: '#f45905',
    },
    tertiary: {
      main: '#fbe555',
    },
  }
});

theme.typography.h5 = {
  fontFamily: "'Montserrat Alternates', sans-serif",
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

export { theme }
